<template>
    <build-env/>
</template>

<script>
import BuildEnv from '../components/BuildEnv.vue'
export default {
  components: { BuildEnv },

}
</script>
<style>

</style>