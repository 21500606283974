<template>
  <iframe
    src="https://vanderbilt.app.box.com/f/e20c775671a747e0a2b3dbc44229dfe9"
    sandbox="allow-scripts
    allow-same-origin"
    height="100%"
    width="100%"
  >
  </iframe>
</template>
<script>
export default {
  name: "UploadDocs",
};
</script>
